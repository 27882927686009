import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';

import svg_logo_bm from '../../resources/img/svg/logo-biuromaklerskie.svg';
import svg_logo_sm from '../../resources/img/svg/logo-supermakler.svg';

const Header = () => {
  return (
    <header className={'header'}>
      <Container>
        <Row>
          <Col>
            <div className={'header__content'}>
            <img src={svg_logo_bm} alt={'Logo Biuro Maklerskie'}/>
            {/*<img src={svg_logo_sm} alt={'Logo Supermakler'}/>*/}
            </div>
          </Col>
        </Row>
      </Container>
    </header>
  );
};

export default Header;